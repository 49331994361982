import React from "react";
import Layout from "../components/layout/Layout";
import ContactForm from "../components/form/ContactForm";

const Contact = () => {
  return (
    <Layout>
      <div className="flex flex-col md:flex-row items-center py-32 px-8 justify-around">
        <ContactForm />
        <div className=" hidden md:flex justify-between flex-col px-16 border-black map">
          <div
            id="contact-map_1"
            className="flex border-b-2 font-black flex-col md:flex-row border-black items-center animate__animated py-36 px-8  w-full gap-16 justify-between"
          >
            <div className="flex-col md:gap-8 flex">
              <h4 className="bebas-neue-regular uppercase text-2xl md:text-6xl tracking-tighter">
                Dallas
              </h4>
              <p className=" uppercase text-md md:text-lg tracking-tighter">
                998 Bellaire Blvd, Lewisville, TX 75067
              </p>
            </div>
            <iframe
              title="Mapa de Google"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3345.1647792073586!2d-97.0146097!3d33.025788999999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c2d7e8468eba1%3A0xbd8b4973d219938e!2s998%20Bellaire%20Blvd%2C%20Lewisville%2C%20TX%2075067!5e0!3m2!1sen!2sus!4v1719498089999!5m2!1sen!2sus"
              width="400"
              height="300"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div
            id="contact-map_2"
            className="flex  font-black flex-col md:flex-row  items-center animate__animated py-36 px-8  w-full gap-16 justify-between"
          >
            <div className="flex-col md:gap-8 flex">
              <h4 className="bebas-neue-regular uppercase text-2xl md:text-6xl tracking-tighter">
                Buenos Aires
              </h4>
              <p className=" uppercase text-md md:text-lg tracking-tighter">
                Arismendi 2620, Autónoma de Buenos Aires, Argentina
              </p>
            </div>
            <iframe
              title="Mapa de Google"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13139.155413793496!2d-58.49469466691373!3d-34.58420884479814!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb66d30c8c22f%3A0x7a317054fe27a37f!2sComuna%2015%2C%20Arismendi%202620%2C%20C1427DLE%20Cdad.%20Aut%C3%B3noma%20de%20Buenos%20Aires%2C%20Argentina!5e0!3m2!1sen!2sus!4v1719498405678!5m2!1sen!2sus"
              width="400"
              height="300"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
