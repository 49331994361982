import React, { useState } from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import {
  FaInstagram,
  FaFacebook,
  FaChevronRight,
  FaBars,
  FaWhatsapp,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import useObserverHeader from "../../helpers/observer.js";

const Layout = ({ children }: any) => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  useObserverHeader();

  return (
    <div className={`relative`}>
      <Navbar.Nav
        classname={`px-6  text-yellow-600 justify-between w-screen z-20 bg-black min-h-screen transition-all ease-in-out duration-300 fixed md:hidden flex flex-col ${
          isNavOpen ? "right-0" : "-right-full"
        }`}
      >
        <div className="flex justify-between">
          <ul className="flex h-full flex-col gap-4 ">
            <span className="bebas-neue-regular uppercase font-black leading-10 text-2xl">
              devstudios
            </span>
            <li className="hover:underline underline-offset-2 hover:bg-white h-full grid transition-all ease-in-out">
              <Link to="/">Home</Link>
            </li>
            <li className="hover:underline underline-offset-2 hover:bg-white h-full transition-all ease-in-out">
              <Link to="/contact">Contáctanos</Link>
            </li>
            <li className="hover:underline underline-offset-2 hover:bg-white h-full transition-all ease-in-out">
              <Link to="/about">Nosotros</Link>
            </li>
          </ul>
          <div className="py-3">
            <button onClick={toggleNav}>
              <FaChevronRight />
            </button>
          </div>
        </div>
        <ul className="flex gap-8">
          <li className="hover:scale-125 hover:shadow-lg rounded-full hover:bg-white min-h-10 place-content-center grid transition-all ease-in-out ">
            <a href="https://www.instagram.com/supercolombianafood/">
              <FaInstagram />
            </a>
          </li>
          <li className="hover:scale-125 hover:shadow-lg rounded-full hover:bg-white place-content-center grid transition-all ease-in-out ">
            <a href="https://www.instagram.com/supercolombianafood/">
              <FaFacebook />
            </a>
          </li>
          <li className="hover:scale-125  rounded-full  place-content-center grid transition-all ease-in-out ">
            <a href="https://api.whatsapp.com/send?phone=12147384575">
              <FaWhatsapp />
            </a>
          </li>
        </ul>
      </Navbar.Nav>
      <Navbar
        id="header"
        classname={
          "bg-black fixed px-6 md:px-8 z-10 text-yellow-600 grid-cols-4 md:grid-cols-10 flex justify-between md:grid gap-20 h-10 items-center w-screen transition-all ease-linear duration-300"
        }
      >
        <Navbar.Brand classname={"h-10 col-span-3  flex "}>
          <span className="bebas-neue-regular uppercase font-black leading-10 text-2xl">
            devstudios
          </span>
        </Navbar.Brand>

        {/* Botón de hamburguesa visible en pantallas pequeñas */}
        <div className="md:hidden flex col-span-1 col-start-4 justify-items-center place-content-center">
          <button onClick={toggleNav}>
            <FaBars />
          </button>
        </div>

        <Navbar.Nav
          classname={`col-span-3 h-full col-start-5 md:flex hidden md:flex`}
        >
          <ul className="grid h-full gap-4 grid-cols-3">
            <li className="hover:underline underline-offset-2 hover:bg-white h-full place-content-center grid transition-all ease-in-out">
              <Link to="/">Home</Link>
            </li>
            <li className="hover:underline underline-offset-2 hover:bg-white h-full place-content-center grid transition-all ease-in-out">
              <Link to="/contact">Contact</Link>
            </li>
            <li className="hover:underline underline-offset-2 hover:bg-white h-full place-content-center grid transition-all ease-in-out">
              <Link to="/about">About Us</Link>
            </li>
          </ul>
        </Navbar.Nav>

        <Navbar.Sm classname={"col-start-9 hidden md:flex"}>
          <ul className="flex gap-8">
            <li className="hover:scale-125 hover:shadow-lg rounded-full hover:bg-white min-h-10 place-content-center grid transition-all ease-in-out ">
              <a href="https://www.instagram.com/supercolombianafood/">
                <FaInstagram />
              </a>
            </li>
            <li className="hover:scale-125 hover:shadow-lg rounded-full hover:bg-white place-content-center grid transition-all ease-in-out ">
              <a href="https://www.instagram.com/supercolombianafood/">
                <FaFacebook />
              </a>
            </li>
          </ul>
        </Navbar.Sm>
      </Navbar>

      {children}

      <Footer />
    </div>
  );
};

export default Layout;
