import { useEffect } from "react";

const useObserverHeader = () => {
  useEffect(() => {   
    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.remove('scale-0'); // Agrega la clase al elemento que está intersectando

          entry.target.classList.add('animate__fadeInUp'); // Agrega la clase al elemento que está intersectando
          observer.unobserve(entry.target); // Deja de observar este elemento después de agregar la clase
        }
        else {
          entry.target.classList.add('scale-0'); // Agrega la clase al elemento que está intersectando

        }
      });
    };

    const observer = new IntersectionObserver(callback, {
      root: null, // Usar el viewport como root
      threshold: 0, // Umbral de visibilidad del 20%
    });

    // Los elementos que deseas observar
    const targets = [
      document.getElementById('home-text_1'),
      document.getElementById('home-text_2'),
      document.getElementById('home-text_3'),

      document.getElementById('home-img_1'),
      document.getElementById('home-img_2'),

      document.getElementById('footer-img_1'),
      document.getElementById('footer-text_2'),
      document.getElementById('footer-text_1'),
      document.getElementById('footer-text_3'),
      document.getElementById('footer-text_4'),

      document.getElementById('contact-map_1'),
      document.getElementById('contact-map_2'),



      // Añade aquí más elementos según sea necesario
    ];

    // Observar cada elemento si existe
    targets.forEach((target) => {
      if (target) {
        observer.observe(target);
      }
    });

    // Devuelve la instancia del IntersectionObserver si lo necesitas
    return () => {
      targets.forEach((target) => {
        if (target) {
          observer.unobserve(target);
        }
      });
    };
  }, []); // Ejecutar una vez al montar

  // Puedes devolver cualquier cosa que desees que el componente que use este hook pueda usar
  return null;
};

export default useObserverHeader;
