import React from "react";
import Layout from "../components/layout/Layout";
import banner from "../assets/banner.jpg";
import laptop from "../assets/laptop.png";

import phone from "../assets/phone.png";
import ContactForm from "../components/form/ContactForm";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <Layout>
      <div
        id="banner"
        className=" overflow-hidden bg-black md:max-h-screen relative transition-all ease-linear duration-300"
      >
        <div className="h-screen ">
          <img
            src={banner}
            className=" relative h-screen w-auto md:w-screen md:max-h-screen"
          ></img>
        </div>
        <div className="-z-5 absolute p-8 gap-8 bottom-0 md:bottom-20 md:left-0 text-yellow-600 flex flex-col md:flex-row justify-around md:items-end w-screen ">
          <div className=" w-5/12">
            <p className="bebas-neue-regular w-fit uppercase font-black text-3xl md:text-6xl animate__animated animate__fadeInUp">
              software a medida Soluciones web para tu negocio
            </p>
          </div>

          <Link
            className="transition-all w-fit ease-linear duration-100 hover:bg-yellow-600 hover:text-black border-2 border-yellow-600 rounded-full h-fit py-2 px-12"
            to="/contact"
          >
            Contáctanos
          </Link>
        </div>
      </div>
      <div className="px-8 py-32 bg-black text-slate-50 ">
        <div className="flex flex-col items-center md:items-start gap-8 md:justify-around justify-center text-center md:text-left md:flex-row">
          <p className="bebas-neue-regular text-md w-full md:w-1/4 ">
            Creamos soluciones digitales personalizadas para optimizar procesos
            y maximizar eficiencia, además, fortalecemos nuestra presencia web
            para atraer nuevos clientes. ¡Únete!
          </p>
          <p
            id="home-text_1"
            className="animate__animated bebas-neue-regular text-slate-50 uppercase font-black text-3xl md:text-5xl w-full md:w-2/4 tracking-tighter"
          >
            Impulsa tu marca con herramientas digitales efectivas
          </p>
        </div>

        <div className="py-36 flex flex-col md:flex-row justify-center">
          <img
            src={phone}
            className="md:w-1/4 w-9/12 animate__animated"
            id="home-img_1"
          ></img>
          <div className="md:w-1/4 w-9/12 relative ">
            <img
              className="absolute md:-bottom-20 -bottom-10 md:-left-20 left-20 animate__animated"
              src={laptop}
              id="home-img_2"
            ></img>
          </div>
        </div>
        <div className="py-24 flex justify-center gap-24 flex-col md:flex-row">
          <div className=" flex  text-center  flex-col">
            <p>Landing pages</p>•<p>Aplicaciones web</p>•<p>Tiendas en línea</p>
            •<p>Sitios web corporativos</p>•<p>Blogs personalizados</p>
          </div>
          <div className=" flex text-center  flex-col">
            <p>Foros y comunidades en línea</p>•<p>Portafolios en línea</p>•
            <p>Sistemas de gestión de contenido</p>•
            <p>Herramientas de gestión de proyectos</p>•
            <p>Sistemas de reserva y citas en línea</p>
          </div>
        </div>
      </div>
      <div className="flex items-center flex-col md:flex-row py-36 px-8  md:h-screen bg-slate-50 gap-16 justify-around">
        <div className="md:w-1/3 flex-col gap-8 flex">
          <h4 className="bebas-neue-regular uppercase font-black text-2xl md:text-5xl tracking-tighter">
            Programa una visita
          </h4>
          <p className="animate__animated" id="home-text_2">
            Agenda una cita con uno de nuestros asesores para aclarar cualquier
            duda que tengas sobre nuestros paquetes y los servicios que mejor se
            adapten a tus necesidades.
          </p>
        </div>
        <ContactForm />
      </div>
      <div className="p-8 md:p-32 flex items-center  text-center  border-y-2 border-black h-screen bg-gradient-to-r from-teal-200 from-10% via-orange-300 via-30%  to-zinc-400 to-80% ...">
        <p
          id="home-text_3"
          className="animate__animated bebas-neue-regular uppercase text-slate-50 font-black text-2xl md:text-5xl tracking-tighter"
        >
          Una sólida presencia web es esencial en la era actual para destacar,
          conectar y prosperar en un mundo digitalmente impulsado.
        </p>
      </div>
    </Layout>
  );
};

export default Home;
