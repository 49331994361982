import React from "react";
import Layout from "../components/layout/Layout";
import dev from "../assets/dev.png";

const About = () => {
  return (
    <Layout>
      <div className="md:h-screen flex flex-col-reverse md:flex-row items-center px-8 py-32 gap-20 map bg-black text-slate-50 ">
        <div className="items-center md:w-2/3 gap-8 flex flex-col">
          <p>
            Somos una empresa especializada en desarrollo web, dedicada a
            ofrecer servicios a empresas y negocios que buscan fortalecer su
            presencia en internet. Con un equipo distribuido entre Estados
            Unidos y Argentina, combinamos lo mejor de ambas regiones para crear
            soluciones innovadoras y efectivas. Nos distinguimos por nuestra
            idiosincrasia global, que se traduce en un enfoque único y adaptado
            a las necesidades locales de cada cliente.
          </p>
          <p>
            Nuestra firma se destaca por su compromiso con la responsabilidad y
            la eficiencia, reflejado en un código de alto nivel y en el
            desarrollo de aplicaciones con las más modernas tecnologías
            disponibles en el ámbito del desarrollo de software. Estamos aquí
            para impulsar el éxito digital de nuestros clientes, asegurando
            soluciones robustas y a la vanguardia del mercado
          </p>
        </div>
        <div className=" flex  justify-center">
          <img src={dev} className=" relative w-screen md:w-2/4"></img>
        </div>
      </div>
    </Layout>
  );
};

export default About;
