import React, { useState } from "react";
import FooterBrand from "./subcomponets/FooterBrand";
import FooterDescription from "./subcomponets/FooterDescription";
import FooterNav from "./subcomponets/FooterNav";
import FooterScMedia from "./subcomponets/FooterScMedia";

import laptop2 from "../../assets/laptop.jpg";
import laptop3 from "../../assets/laptop2.jpg";
import coffee from "../../assets/coffee.jpg";
import mockup from "../../assets/mockup.jpg";
import finances from "../../assets/finances.jpg";
import Modal from "../common/Modal.tsx";
import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa";

const Footer = ({ children }: any) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className="bg-black overflow-hidden">
      {children}
      <div
        id="footer-text_1"
        className="flex flex-col md:flex-row  items-center animate__animated py-36 px-8 text-center text-slate-50  bg-black gap-16 justify-around"
      >
        <div className="w-1/3 flex-col md:gap-8 flex">
          <h4 className="bebas-neue-regular uppercase text-2xl md:text-5xl font-black  tracking-tighter">
            Dallas
          </h4>
          <p className="bebas-neue-regular uppercase text-md  md:text-xl tracking-tighter">
            Texas - US
          </p>
        </div>
        <div className="w-1/3 flex-col md:gap-8 flex">
          <h4 className="bebas-neue-regular uppercase font-black text-2xl md:text-5xl  tracking-tighter">
            Buenos Aires
          </h4>
          <p className="bebas-neue-regular uppercase text-md  md:text-xl tracking-tighter">
            CABA - Argentina
          </p>
        </div>
      </div>
      <div
        id="footer-img_1"
        className="flex flex-col md:flex-row animate__animated items-center border-y-2 border-slave-50  text-center text-slate-50  bg-black  justify-around"
      >
        <div className="overflow-hidden">
          <img
            src={laptop2}
            className=" relative hover:hover:scale-105 transition-all ease-linear duration-200"
          ></img>
        </div>
        <div className="overflow-hidden hidden md:flex">
          <img
            src={finances}
            className=" relative hover:hover:scale-105 transition-all ease-linear duration-200"
          ></img>
        </div>

        <div className="overflow-hidden">
          <img
            src={coffee}
            className=" relative hover:hover:scale-105 transition-all ease-linear duration-200"
          ></img>
        </div>
        <div className="overflow-hidden hidden md:flex">
          <img
            src={laptop3}
            className=" relative hover:hover:scale-105 transition-all ease-linear duration-200"
          ></img>
        </div>
        <div className="overflow-hidden">
          <img
            src={mockup}
            className=" relative hover:hover:scale-105 transition-all ease-linear duration-200"
          ></img>
        </div>
      </div>
      <div
        id="footer-text_2"
        className="flex animate__animated flex-col md:flex-row   py-36 px-8  text-slate-50  bg-black gap-16 justify-around"
      >
        <div
          id="footer-text_2"
          className="md:w-2/4 lg:w-1/4 flex-col md:gap-8 flex  text-center md:text-left"
        >
          <h4 className="bebas-neue-regular uppercase text-2xl lg:text-3xl font-black  tracking-tighter">
            Redes sociales
          </h4>
          <ul className="flex gap-8  place-content-center md:place-content-start">
            <li className="hover:scale-125  rounded-full  min-h-10 place-content-center grid transition-all ease-in-out ">
              <a
                href="https://www.instagram.com/s.tudiodev/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram />
              </a>
            </li>
            <li className="hover:scale-125  rounded-full  place-content-center grid transition-all ease-in-out ">
              <a
                href="https://www.instagram.com/s.tudiodev/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook />
              </a>
            </li>
            <li className="hover:scale-125  rounded-full  place-content-center grid transition-all ease-in-out ">
              <a
                href="https://api.whatsapp.com/send?phone=12147384575"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaWhatsapp />
              </a>
            </li>
          </ul>
          {/* <h4 className="bebas-neue-regular uppercase text-2xl lg:text-3xl font-black  tracking-tighter">
            Soporte
          </h4> */}
          {/* <p
            onClick={() => setModalOpen(!modalOpen)}
            className="bebas-neue-regular  text-sm md:text-md tracking-tighter"
          >
            Términos y condiciones
          </p> */}
        </div>

        <div
          id="footer-text_3"
          className="md:w-2/4 lg:w-1/4 flex-col md:gap-8 flex  text-center md:text-left flex flex-col gap-8"
        >
          <h4 className="bebas-neue-regular uppercase font-black text-2xl lg:text-3xl tracking-tighter">
            Contacto
          </h4>
          <p className="bebas-neue-regular  text-sm md:text-md tracking-tighter">
            devcontact.studio@gmail.com
          </p>
          <p className="bebas-neue-regular  text-sm md:text-md tracking-tighter">
            +1 214 738 4575
          </p>
        </div>
        <div
          id="footer-text_4"
          className="w-full flex items-center justify-center flex-col gap-8 text-center"
        >
          <h4 className="bebas-neue-regular uppercase font-black text-3xl lg:text-4xl tracking-tighter">
            devstudio
          </h4>
        </div>
      </div>
      <Modal isOpen={modalOpen}>
        <div className="flex justify-around">
          <h2>¡Hola!</h2>
          <button onClick={() => setModalOpen(!modalOpen)}>x</button>
        </div>
        <p>Este es el contenido del modal.</p>
      </Modal>
    </div>
  );
};

Footer.Brand = FooterBrand;
Footer.Description = FooterDescription;
Footer.Nav = FooterNav;
Footer.ScMedia = FooterScMedia;

export default Footer;
